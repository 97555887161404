export const features = [
  'Affordable, scalable and performant. The perfect solution for small apps.',
  'A mid-sized solution for businesses undergoing rapid user growth.',
  "A farm of machines entirely dedicated to your company's storage needs.",
  'A mid-sized solution for businesses undergoing rapid user growth.',
];

export const jobs = [
  {
    title: 'Front-End Developer',
    location: 'Madrid',
    type: 'Remote',
  },
  {
    title: 'Community Manager',
    location: 'Paris',
    type: 'Full time',
  },
  {
    title: 'UX/UI Designer',
    location: 'Yerevan',
    type: 'Part time',
  },
  {
    title: 'Front-End Developer',
    location: 'Madrid',
    type: 'Remote',
  },
  {
    title: 'Community Manager',
    location: 'Paris',
    type: 'Full time',
  },
  {
    title: 'UX/UI Designer',
    location: 'Yerevan',
    type: 'Part time',
  },
  {
    title: 'Front-End Developer',
    location: 'Madrid',
    type: 'Remote',
  },
  {
    title: 'Community Manager',
    location: 'Paris',
    type: 'Full time',
  },
  {
    title: 'UX/UI Designer',
    location: 'Yerevan',
    type: 'Part time',
  },
];
